<template>
  <form class="row g-1 p-0 p-md-4" @submit.prevent="submit">
    <div class="col-12 text-center">
      <h1>Crea tu cuenta</h1>
      <!-- <h4>acceso gratis!!!</h4> -->
    </div>
    <div class="col-12">
      <div class="mb-2">
        <label class="form-label">Nombre completo</label>
        <input required v-model="row.nombre" type="text" class="form-control form-control-lg" placeholder="Juan López" />
      </div>
    </div>
    <!-- <div class="col-6">
            <div class="mb-2">
                <label class="form-label">{{'\xa0'}}</label>
                <input type="email" class="form-control form-control-lg" placeholder="Parker">
            </div>
        </div> -->
    <div class="col-12">
      <div class="mb-2">
        <label class="form-label">Email</label>
        <input required v-model="row.email" type="email" class="form-control form-control-lg" placeholder="juanlopez@example.com" />
      </div>
    </div>
    <div class="col-12">
      <div class="mb-2">
        <label class="form-label">Contraseña</label>
        <input required v-model="row.clave" type="password" class="form-control form-control-lg" placeholder="8+ characters required" />
      </div>
    </div>
    <!-- <div class="col-12">
            <div class="mb-2">
                <label class="form-label">Confirmar Contraseña</label>
                <input type="password" class="form-control form-control-lg" placeholder="8+ characters required">
            </div>
        </div> -->
    <div class="col-12">
      <div class="form-check">
        <input required class="form-check-input" type="checkbox" value="" id="flexCheckDefault" />
        <label class="form-check-label" for="flexCheckDefault"> Acepto los <a href="#" title="" class="text-primary">Términos y Condiciones</a> </label>
      </div>
    </div>
    <div class="col-12 text-center mt-4">
      <button type="submit" class="btn btn-lg btn-dark lift text-uppercase">REGISTRO</button>
    </div>
    <div class="col-12 text-center mt-4">
      <button @click="$router.push('/usosToolB')" class="btn btn-lg btn-dark lift text-uppercase">REGISTRO v2</button>
    </div>
    <div class="col-12 text-center mt-4">
      <span class="text-muted">¿Ya tienes una cuenta? <router-link to="/authentication/auth-login">Inicia sesión aqui</router-link></span>
    </div>
  </form>
</template>
<script>
export default {
  name: "Sign_upComponent",
  data() {
    return {
      row: {},
      companyId: undefined,
    };
  },
  mounted() {
    this.companyId = this.$route.params.companyId;
    console.log(this.companyId);
  },
  methods: {
    async submit() {
      this.row.fechacreacion = this.$moment().utc();
      this.row.fechaalta = this.$moment().utc();
      this.row.empresa_id = this.companyId;
      //   if (this.companyId != undefined) {
      //   }
      let res = await this.$store.dispatch("post", { path: "usuarios/newUserFromRegister", data: this.row });
      console.log(res);
      if (res.result == 1) {
        this.$swal({ title: "Muy bien!", text: res.message, icon: "success" });
        this.$router.push({ path: "/authentication/auth-login" });
      } else {
        this.$swal({ title: "Aviso", text: "Error: " + res.message, icon: "error" });
      }
      //e.preventDefault()
    },
  },
};
</script>
<style scoped></style>
